
import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = () => (
	<Layout>

<div className="flex flex-row py-5 gap-5">
	<div className="w-7/12">
		<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8153.882484228705!2d79.85425724915237!3d6.905113183430545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25960af1be0a7%3A0xe229bf9b47bd75af!2sSigntech+Advertising+(Pvt)+Ltd!5e0!3m2!1sen!2slk!4v1439187977410" width="100%" height="670"  style={{border: 0}} ></iframe>
	</div>

	<div className="w-5/12" >
		<div className="grid grid-cols-2">

		<div>
		<p className="text-sm font-semibold">
		Sign-Tech Advertising (Pvt) Ltd
		</p>

		<p className="text-xs" >
		85B <br /> 
		Srimath Anagarika Dharmapala Mawatha, <br />
		Colombo 07. <br />
		Sri Lanka.
		</p>

		</div>
		<div>
			<p className="text-sm text-right">
		Hotline - 0112554609<br />
			+94 11 2564371<br />
			+94 11 4712465
			</p>
		</div>
		</div>

		<p className="py-5 text-sm">sales@signtechads.com</p>

	<div>

	<input className="w-full mb-3 p-2 border border-gray-600" placeholder="Name *" name="name" type="text"/>
	<input className="w-full mb-3 p-2 border border-gray-600" placeholder="Email *" name="email" type="email"/>
	<input className="w-full mb-3 p-2 border border-gray-600" placeholder="Contact No *" name="phone" type="text"/>
	<textarea rows={7} className="w-full mb-3 p-2 border border-gray-600"  placeholder="Inquiry / Message *" name="contactmessage" ></textarea>
		
	<input className="w-4/12 p-3 bg-gray-700 uppercase text-sm text-gray-400" type="submit" value="Send"/>

	</div>

	</div>





		</div>

	</Layout>
)

export default ContactPage